import { Link } from "react-router-dom";
import { useState, useContext } from "react";
import { InputField, MainButton, Loader } from "commonUI";
import "./auth.css";
import { useNavigate } from "react-router-dom";
import { UserAuthContext } from "context/UserAuth";
import { useMutation } from "@tanstack/react-query";
import { userLogin } from "api/userAuth";
import { Logo } from "allIcons";
import { ApiResponseType } from "enums";

export default function Login() {
  const { setUserAuth } = useContext(UserAuthContext);
  const navigate = useNavigate();
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
    error: "",
  });

  const loginHandler = (data) => {
    localStorage.setItem("user_id", data.user_id);
    localStorage.setItem("token", data.token);
    setUserAuth((pre) => ({
      ...pre,
      token: data.token,
    }));
    setUserInput({
      ...userInput,
      error: "",
    });
    navigate("/user-workspaces");
  };

  const mutation = useMutation(userLogin, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.CONFLICT) {
        setUserInput({
          ...userInput,
          error: data.Message,
        });
        return;
      }
      if (data.token) {
        loginHandler(data);
      } else {
        setUserInput({
          ...userInput,
          error: "Invalid email or password",
        });
      }
    },
    onError: (error) => {
      setUserInput({
        ...userInput,
        error: "Invalid email or password",
      });
    },
  });

  return (
    <>
      <Loader show={mutation.isLoading} />
      <div className={`login-container`}>
        <div className="">
          <div
            className={`flex items-center bg-transparent mb-12 justify-center`}
          >
            <Logo className="logo-size" />
          </div>
          <div className="login-form">
            <h2 className="login-heading w-full">Log In</h2>
            <div className="py-4 w-fit mx-auto">
              {userInput.error && (
                <p className="error-text mb-1">{userInput.error}</p>
              )}
              <InputField
                type="text"
                name="email"
                id="email_address"
                placeholder="Write Email Here"
                value={userInput.email}
                setValue={(value) =>
                  setUserInput({ ...userInput, email: value, error: "" })
                }
                inputClass="py-1"
              />
              <InputField
                type="password"
                name="password"
                id="password"
                placeholder="Write Password Here"
                value={userInput.password}
                setValue={(value) =>
                  setUserInput({ ...userInput, password: value, error: "" })
                }
                inputClass="py-1"
              />
              <div className="my-2">
                <Link to={"/forgot-password"} className="forget-link">
                  Forget Password?
                </Link>
              </div>
              <div className="py-2">
                <MainButton
                  onClick={() =>
                    mutation.mutate({
                      email: userInput.email,
                      password: userInput.password,
                    })
                  }
                  className={`w-full py-2 `}
                >
                  Login
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
