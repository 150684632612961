import { SaveIcon } from "allIcons";
import {
  useInterface,
  useUpdateInterfaceSetting,
} from "api/siteSettings/interfaceSetting";
import {
  BodyContainer,
  HeaderContainer,
  Loader,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  SelectField,
  ToggleCard,
} from "commonUI";
import { parse, useSearch } from "helpers";
import { useEffect, useState } from "react";

export default function Interface() {
  const [values, setValues] = useState({
    open_main_window: "Same Docked Window",
    open_child_from_dock: "Same Docked Window",
    open_child_from_floating: "Same Floating Window",
    show_comment_outline: true,
  });
  const { web = null } = useSearch();
  const { data, website_id, isLoading: loadingInterface } = useInterface();
  const { mutate: updateInterface, isLoading: updatinInterface } =
    useUpdateInterfaceSetting();

  useEffect(() => {
    if (data?.interface_settings && web === website_id) {
      const settings = parse(data.interface_settings);
         setValues(settings);
    }
  }, [data, website_id]);

  const handleSave = () => {
    updateInterface({
      interface_settings: values,
      system_status: data.system_status,
      website_id: data?.website_id || web,
      apiKey: data?.apiKey || localStorage.getItem("apikey"),
      added_by: data?.added_by || localStorage.getItem("user_id"),
    });
  };

  return (
    <>
      <Loader show={updatinInterface} loader="block" />
      <RightMainContainer>
        <HeaderContainer>
          <PrimaryHeading>Interface</PrimaryHeading>
          <MainButton
            onClick={handleSave}
            disabled={updatinInterface || loadingInterface}
          >
            <SaveIcon /> Save Changes
          </MainButton>
        </HeaderContainer>
        <BodyContainer>
          <SelectField
            label="Open All Items By Default In"
            placeholder="Same Docked Window"
            value={values.open_main_window}
            setValue={(value) =>
              setValues({ ...values, open_main_window: value })
            }
          >
            <option value="Same Docked Window">Same Docked Window</option>
            <option value="Same Floating Window">Same Floating Window</option>
            <option value="New Floating Window">New Floating Window</option>
          </SelectField>
          <SelectField
            label="Open Items From Docked Window In"
            placeholder="Same Docked Window"
            value={values.open_child_from_dock}
            setValue={(value) =>
              setValues({ ...values, open_child_from_dock: value })
            }
          >
            <option value="Same Docked Window">Same Docked Window</option>
            <option value="Same Floating Window">Same Floating Window</option>
          </SelectField>
          <SelectField
            label="Open Items From Floating Windows In"
            placeholder="Same Floating Window"
            value={values.open_child_from_floating}
            setValue={(value) =>
              setValues({
                ...values,
                open_child_from_floating: value,
              })
            }
          >
            <option value="Same Floating Window">Same Floating Window</option>
            <option value="New Floating Window">New Floating Window</option>
          </SelectField>

          <ToggleCard
            label="Show Comments Outline"
            value={values.show_comment_outline}
            setValue={(value) =>
              setValues({ ...values, show_comment_outline: value })
            }
          />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
