import { ApprovalIcon, EnvironmentIcon, MaintenanceIcon } from "allIcons";

export const featureMenu = (web) => {
  return [
    // {
    //   module: "launchpad-widgets",
    //   title: "Launchpad Widgets",
    //   icon: <LaunchPadIcon />,
    // },
    {
      module: "environments",
      title: "Environments",
      icon: <EnvironmentIcon />,
    },
    web && {
      module: "maintenance",
      title: "Maintenance",
      icon: <MaintenanceIcon />,
    },
    {
      module: "approvals",
      title: "Approvals",
      icon: <ApprovalIcon />,
    },
  ].filter(_ => _);
};
