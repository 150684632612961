import { useQuery } from "@tanstack/react-query";
import { axiosInstance } from "helpers";

const getWorkspaces = async () => {
  return axiosInstance
    .get("workspaces", {
      params: {
        user_id: localStorage.getItem("user_id"),
      },
    })
    .then((res) => res.data);
};

export const useWorkSpaces = () => {
  return useQuery(["workspaces"], getWorkspaces);
};
