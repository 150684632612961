import { ModulePrefixes } from "enums";
import LeftMenuCard from "./LeftMenuCard";
import { useLocation } from "react-router-dom";
import { siteTempleteUsersMenu } from "staticData";

export default function SiteTempleteUsersMenu() {
  const { search } = useLocation();
  return (
    <>
      <div className="card-div">
        {siteTempleteUsersMenu.map((item, index) => (
          <LeftMenuCard
            key={`${index}-${item.module}`}
            {...item}
            prefix={ModulePrefixes.SITE_SETTINGS}
            link={`${ModulePrefixes.SITE_SETTINGS}/${item.module}${
              search ?? ""
            }`}
          />
        ))}
      </div>
    </>
  );
}
