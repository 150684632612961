import { useWorkSpaces } from "api/workSpaces";
import {
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PrimaryHeading,
} from "commonUI";
import "./auth.css";
import { useState } from "react";
import { useUserAuth } from "context/UserAuth";
import { useNavigate } from "react-router-dom";
import { activateLicense, useCheckKeyStatus } from "api/userAuth";
import { parse, useUtilities } from "helpers";
import { ApiResponseType } from "enums";

const checkRolePermission = (app_permissions) => {
  const appPermissions = parse(app_permissions);
  return appPermissions.app_general_log_into_app;
};

const WorkSpaces = () => {
  const { data, isLoading } = useWorkSpaces();
  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const { notification } = useUtilities();
  const { setUserAuth, userAuth } = useUserAuth();
  const navigate = useNavigate();

  const { mutateAsync: checkKeyStatus, isLoading: checkingKeyStatus } =
    useCheckKeyStatus();

  const createUserSession = () => {
    localStorage.setItem("apikey", selectedWorkspace.key);
    setUserAuth((pre) => ({
      ...pre,
      apikey: selectedWorkspace.key,
    }));
    navigate("/");
  };

  const handleProceed = (event) => {
    event.preventDefault();
    const isUserAllowed = checkRolePermission(
      selectedWorkspace.current_user.role.app_permissions
    );
    if (!isUserAllowed) {
      notification({
        message: "You don't have login permission for this workspace.",
        type: "error",
      });
      return;
    }
    checkKeyStatus({ apikey: selectedWorkspace.key })
      .then((res) => {
        if (res.StatusCode === ApiResponseType.SUCCESS) {
          createUserSession();
        } else {
          activateLicense({
            apiKey: selectedWorkspace.apikey,
          }).then((res) => {
            if (res.StatusCode === ApiResponseType.SUCCESS) {
              createUserSession();
            } else {
              notification({
                message:
                  "Your license is not activated. Please contact support.",
                type: "error",
              });
            }
          });
        }
      })
      .catch((err) => {
        notification({
          message: "Your license is not activated. Please contact support.",
          type: "error",
        });
      });
  };

  return (
    <>
      <Loader show={checkingKeyStatus} />
      <div className="h-screen flex items-center">
        <div className="workspace-container">
          <PrimaryHeading>Select Workspace</PrimaryHeading>
          {isLoading ? (
            <LoadingListCards />
          ) : (
            (data?.Data || []).map((ele) => {
              return (
                <ListCard
                  selected={ele.id === selectedWorkspace.id}
                  data={ele.title}
                  onClick={() => setSelectedWorkspace(ele)}
                  icon={
                    <ListIconWrapper className="border-white text-white">
                      {ele.title[0]}
                    </ListIconWrapper>
                  }
                  deleteIcon={true}
                  editIcon={false}
                  secondaryData={ele.status}
                />
              );
            })
          )}
          <MainButton
            className="workspace-container-proceed-button"
            disabled={!selectedWorkspace}
            onClick={handleProceed}
          >
            Proceed
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default WorkSpaces;
