export const ModulePrefixes = {
  SITE_SETTINGS: "site-settings",
  TEMPLATE_LIBRARY: "template-library",
  SITE_SETTINGS_LIBRARY_ITEM: "site-settings/library-item",
  USERS: "users",
  SITES: "sites",
  SETTINGS: "settings",
};

export const SiteSettingsMenu = {
  GENERAL_SETTINGS: "general-settings",
  FEATURES_SETTINGS: "features-settings",
  USER_PREFRENCES: "users-prefrences",
  LIBRARY_TEMPLATES: "library-templates",
};

export const FeatureModules = {
  BRANDS_AND_COLORING: "Brand",
};

export const ModulesRoutes = {
  GENERAL_SETTINGS: "general-settings",
  INTERFACE: "interface",
  LAUNCHPAD_WIDGETS: "launchpad-widgets",
  USERS: "users",
  TASKS: "tasks",
  ENVIRONMENTS: "environments",
  BRANDING_AND_COLORS: "branding-and-colors"
};
