export default function SingleUserIcon(props) {
  function HandleClick() {
    props.onClick && props.onClick();
  }
  return (
    <>
      <svg
        width="12"
        height="17"
        viewBox="0 0 12 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`cursor-pointer ${props.className ?? ""}`}
        onClick={HandleClick}
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.09839 6.40164C3.80165 7.1049 4.75544 7.5 5.75 7.5C6.74456 7.5 7.69841 7.1049 8.40167 6.40164C9.10493 5.69838 9.5 4.74456 9.5 3.75C9.5 2.75544 9.10493 1.80162 8.40167 1.09836C7.69841 0.395097 6.74456 0 5.75 0C4.75544 0 3.80165 0.395097 3.09839 1.09836C2.39513 1.80162 2 2.75544 2 3.75C2 4.74456 2.39513 5.69838 3.09839 6.40164ZM4.159 2.159C4.58095 1.73704 5.15326 1.5 5.75 1.5C6.34674 1.5 6.91905 1.73704 7.341 2.159C7.76296 2.58095 8 3.15326 8 3.75C8 4.34674 7.76296 4.91905 7.341 5.341C6.91905 5.76296 6.34674 6 5.75 6C5.15326 6 4.58095 5.76296 4.159 5.341C3.73704 4.91905 3.5 4.34674 3.5 3.75C3.5 3.15326 3.73704 2.58095 4.159 2.159ZM0 13.25C0 15.65 2.97 16.5 5.75 16.5C8.53 16.5 11.5 15.65 11.5 13.25C11.5 11.33 9.67 8.5 5.75 8.5C1.83 8.5 0 11.33 0 13.25ZM1.5 13.25C1.5 12.23 2.57 10 5.75 10C8.93 10 10 12.25 10 13.25C10 14.63 7.33 15 5.75 15C4.17 15 1.5 14.63 1.5 13.25Z"
          fill={props.fill ?? "white"}
        />
      </svg>
    </>
  );
}
