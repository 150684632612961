import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { axiosInstance } from "helpers";

export async function userLogin(data) {
  return axios
    .post(`${process.env.REACT_APP_API_URL}login`, {
      ...data,
      login_from: "app",
    })
    .then((res) => res.data)
    .catch((err) => err.response.data);
}

export async function getUserData(userId) {
  return axiosInstance
    .get(`getuserprofile`, {
      params: {
        userId: userId,
        apikey: localStorage.getItem("apikey"),
      },
    })
    .then((res) => ({ ...res.data.Data[0], rolesData: res.data.rolesData }))
    .catch((err) => Promise.reject(err));
}

export async function userLogout() {
  return axiosInstance
    .post("logout")
    .then((res) => res.data)
    .catch((err) => err);
}

export async function checkKeyStatus(data) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}checkkeystatus`, data)
    .then((res) => res.data)
    .catch((err) => err);
}

export function useCheckKeyStatus() {
  return useMutation(checkKeyStatus);
}

export function activateLicense(data) {
  return axiosInstance
    .post(`${process.env.REACT_APP_API_URL}activate_sc_license`, data)
    .then((res) => res.data)
    .catch((err) => err);
}
