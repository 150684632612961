import { useEffect, useState } from "react";
import {
  ToggleCard,
  RightMainContainer,
  HeaderContainer,
  BodyContainer,
  InputField,
  PrimaryHeading,
  SecondaryHeading,
  MainButton,
  Loader,
} from "commonUI";
import { SaveIcon } from "allIcons";
import { isEqual, parse, useSearch } from "helpers";
import { useApprovals, useUpdateApprovalSetting } from "hooks/approvals";
import { useUserPermissions } from "hooks/user";

export function ApprovalCard({
  inputValue,
  handleInputChange,
  checkbox,
  checkboxChange,
  signature,
  signatureChange,
  inputlabel,
  isLoading,
  ...props
}) {
  return (
    <>
      <div className={`${props.className ?? ""}`}>
        <PrimaryHeading>{props.title}</PrimaryHeading>
        <SecondaryHeading className="mb-2 mt-2">
          {props.checkboxlabel}
        </SecondaryHeading>
        <p className="body-text text-theme-placeholder text-11 leading-4">
          {props.detailText}
        </p>
        <div className="my-4">
          <ToggleCard value={checkbox} setValue={checkboxChange} isLoading={isLoading} />
        </div>
        <InputField
          value={inputValue}
          setValue={handleInputChange}
          label={inputlabel}
          placeholder={props.inputplaceholder}
          className="!gap-2 mt-4"
        />
        <SecondaryHeading className="mb-2 mt-2">
          {props.signaturelabel}
        </SecondaryHeading>
        <p className="body-text text-theme-placeholder text-11 leading-4">
          {props.signatureDetail}
        </p>
        <div className="my-4">
          <ToggleCard value={signature} setValue={signatureChange} isLoading={isLoading} />
        </div>
        {props.seprator && <hr className="border-line" />}
      </div>
    </>
  );
}

export default function ApprovalSettings() {
  const { web = null } = useSearch();
  const { data: approvals, isLoading } = useApprovals();
  const { mutate: updateApproval, isLoading: updatingApproval } =
    useUpdateApprovalSetting();
  const [inputValue, setInputValue] = useState({
    site_approval_checkbox: true,
    site_approval_text:
      "I confirm I have reviewed the design, content, and functionality of the entire site and everything meets my approval.",
    site_approval_digital_sign: true,

    page_approval_checkbox: true,
    page_approval_text:
      "I confirm I have reviewed the design, content, and functionality of this page and everything meets my approval.",
    page_approval_digital_sign: true,

    comment_approval_checkbox: false,
    comment_approval_text:
      "I confirm I have reviewed the design, content, and functionality of this comment and everything meets my approval.",
    comment_approval_digital_sign: false,
  });
  const {
    add_new_items_to_site_template,
    edit_item_details_in_site_template,
  } = useUserPermissions();
  const [loading, setLoading] = useState(true);

  const handleInputChange = (key, value) => {
    setInputValue((pre) => ({ ...pre, [key]: value }));
  };

  useEffect(() => {
    setLoading(false);
  }, []);
  useEffect(() => {
    if (isLoading) return;
    const approval = approvals.find((_) => isEqual(_.website_id, web));
    if (approval) {
      setInputValue(parse(approval.setting_options) ?? inputValue);
    }
  }, [web, approvals, isLoading]);

  const handleSaveChanges = () => {
    const approval = approvals.find((_) => isEqual(_.website_id, web));
    updateApproval({
      ...(approval ?? {}),
      website_id: web,
      setting_options: inputValue
    })
  }

  return (
    <>
      <RightMainContainer>
        <Loader show={updatingApproval} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>Approvals</PrimaryHeading>
          {add_new_items_to_site_template || edit_item_details_in_site_template ? <MainButton onClick={handleSaveChanges}>
            <SaveIcon />
            Save Changes
          </MainButton> : ""}
        </HeaderContainer>
        <BodyContainer>
          <ApprovalCard
            title="Site"
            checkboxlabel="Require Approval Confirmation Checkbox For Site"
            detailText="This will add a checkbox and confirmation text to approvals assigned to the site in general which the assigned user needs to confirm before the approval button can be clicked."
            inputlabel="Confirmation Text For Site Approval"
            signaturelabel="Require Digital Signature For Site"
            signatureDetail="This will add an input field to approvals assigned to the site in general which the assigned user needs to confirm by typing their full name before the approval button can be clicked."
            checkbox={inputValue.site_approval_checkbox}
            checkboxChange={(value) =>
              handleInputChange("site_approval_checkbox", value)
            }
            signature={inputValue.site_approval_digital_sign}
            signatureChange={(value) =>
              handleInputChange("site_approval_digital_sign", value)
            }
            inputplaceholder="Enter Confirmation Text For Site Approval Here"
            inputValue={inputValue.site_approval_text}
            handleInputChange={(value) =>
              handleInputChange("site_approval_text", value)
            }
            isLoading={isLoading || loading}
          />
          <ApprovalCard
            className="mt-3"
            title="Pages"
            checkboxlabel="Require Approval Confirmation Checkbox For Pages"
            detailText="This will add a checkbox and confirmation text to approvals assigned to pages which the assigned user needs to confirm before the approval button can be clicked."
            inputlabel="Confirmation Text For Page Approval"
            signaturelabel="Require Digital Signature For Pages"
            signatureDetail="This will add an input field to approvals assigned to pages which the assigned user needs to confirm by typing their full name before the approval button can be clicked."
            checkbox={inputValue.page_approval_checkbox}
            checkboxChange={(value) =>
              handleInputChange("page_approval_checkbox", value)
            }
            signature={inputValue.page_approval_digital_sign}
            signatureChange={(value) =>
              handleInputChange("page_approval_digital_sign", value)
            }
            inputplaceholder="Enter Confirmation Text For Page Approval Here"
            inputValue={inputValue.page_approval_text}
            handleInputChange={(value) =>
              handleInputChange("page_approval_text", value)
            }
          />
          <ApprovalCard
            className="mt-3"
            title="Comments"
            checkboxlabel="Require Approval Confirmation Checkbox For Comments"
            detailText="This will add a checkbox and confirmation text to approvals assigned to comments which the assigned user needs to confirm before the approval button can be clicked."
            inputlabel="Confirmation Text For Comment Approval"
            signaturelabel="Require Digital Signature For Comments"
            signatureDetail="This will add an input field to approvals assigned to comments which the assigned user needs to confirm by typing their full name before the approval button can be clicked."
            checkbox={inputValue.comment_approval_checkbox}
            checkboxChange={(value) =>
              handleInputChange("comment_approval_checkbox", value)
            }
            signature={inputValue.comment_approval_digital_sign}
            signatureChange={(value) =>
              handleInputChange("comment_approval_digital_sign", value)
            }
            inputplaceholder="Enter Confirmation Text For Comment Approval Here"
            seprator={false}
            inputValue={inputValue.comment_approval_text}
            handleInputChange={(value) =>
              handleInputChange("comment_approval_text", value)
            }
          />
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
