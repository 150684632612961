import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomeScreen from "main/HomeScreen";
import ForgotPassword from "userAuth/ForgotPassword";
import Login from "userAuth/Login";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import "@fontsource/open-sans/800.css";
import { useUserAuth } from "context/UserAuth";
import CreatePassword from "userAuth/CreatePassword";
import ResetPassword from "userAuth/ResetPassword";
import WorkSpaces from "userAuth/WorkSpaces";

const NavigateUser = () => {
  const {
    userAuth: { token, apikey },
  } = useUserAuth();
  if (apikey && token) {
    return <Navigate to="/" />;
  } else if (token && !apikey) {
    return <Navigate to="/user-workspaces" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default function Main() {
  const {
    userAuth: { token, apikey },
  } = useUserAuth();

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={token ? <NavigateUser /> : <Login />} />
          <Route path="/signup" element={<Navigate to="/login" replace />} />
          <Route
            path="/create-password"
            element={token ? <NavigateUser /> : <CreatePassword />}
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/reset-password"
            element={token ? <NavigateUser /> : <ResetPassword />}
          />
          <Route
            path="/user-workspaces"
            element={token && !apikey ? <WorkSpaces /> : <NavigateUser />}
          />
          <Route
            path="/*"
            element={token && apikey ? <HomeScreen /> : <NavigateUser />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
