import { addTag, deleteTag, updateTag } from "api/siteSettings/Tags";
import { isEqual, printError, useSearch, useUtilities } from "helpers";
import {
  addSingleTag,
  deleteSingleTag,
  updateSingleTag,
} from "redux/reducers/siteSettings/tagsReducer";
import { useMutation } from "@tanstack/react-query";
import { ApiResponseType, NotificationType } from "enums";

export function useDeleteTag(redirect = "") {
  const { dispatch, notification, navigate } = useUtilities();
  return useMutation(deleteTag, {
    onSuccess: (data) => {
      if (data.StatusCode === 200) {
        dispatch(deleteSingleTag({ id: data.id }));
        notification({
          type: "success",
          message: "Tag deleted successfully",
        });
        if (redirect) navigate(redirect);
      } else {
        notification({
          type: "error",
          message: data.Message,
        });
      }
    },
    onError: (err) => {
      notification({
        type: "error",
        message: "Something went wrong, please try again later",
      });
    },
  });
}

export function useUpdateTag() {
  const { dispatch, notification } = useUtilities();
  return useMutation(updateTag, {
    onSuccess: (data) => {
      if (data.StatusCode === ApiResponseType.SUCCESS) {
        dispatch(updateSingleTag(data.Data));
        notification({
          message: "Tag updated successfully",
          type: "success",
        });
      } else {
        notification({
          message: printError(data.Message),
          type: NotificationType.ERROR,
        });
      }
    },
    onError: (error) => {
      notification({
        message: printError(error?.response?.data?.message),
        type: "error",
      });
    },
  });
}

export function useAddTag(isSettings) {
  const { notification, dispatch, navigate } = useUtilities();
  const { web } = useSearch();
  return useMutation(addTag, {
    onSuccess: (data) => {
      if (!isEqual(data?.StatusCode, ApiResponseType.SUCCESS)) {
        notification({
          message: data?.Message ?? "Something went wrong",
          type: "error",
        });
        return;
      }
      dispatch(addSingleTag(data.Data));
      navigate(
        !isSettings
          ? `/site-settings/tags/${data.Data.id}${web ? `?web=${web}` : ""}`
          : `/settings/tags/${data.Data.id}`
      );
      notification({
        message: "Tag Added Successfully",
        type: "success",
      });
    },
    onError: (error) => {
      notification({
        message:
          error?.response?.data?.message ??
          "Something went wrong, please try again later",
        type: "error",
      });
    },
  });
}
