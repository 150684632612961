import { BrandingIcon, SiteIcon, SMTPIcon, TagsIcon } from "allIcons";
import NotificationIcon from "allIcons/NotificationIcon";

export const settingsMenu = [
  {
    module: "general-settings",
    title: "General",
    icon: <SiteIcon />,
  },
  {
    module: "branding-and-colors",
    title: "Branding & Colors",
    icon: <BrandingIcon />,
  },
  {
    module: "smtp",
    title: "SMTP",
    icon: <SMTPIcon />,
  },
  {
    module: "notifications",
    title: "Notifications",
    icon: <NotificationIcon />,
  },
  {
    module: "tags",
    title: "Tags",
    icon: <TagsIcon />,
  },
  // {
  //   module: "email-templates",
  //   title: "Email Templates",
  //   icon: <MailIcon />,
  // },
  // {
  //   module: "trash",
  //   title: "Trash",
  // icon: <TrashIcon />,
  // },
];
