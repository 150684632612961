import { AddIcon, RolesIcon } from "allIcons";
import {
  BodyContainer,
  FiltersList,
  HeaderContainer,
  InputField,
  ListCard,
  ListIconWrapper,
  Loader,
  LoadingListCards,
  MainButton,
  PrimaryHeading,
  RightMainContainer,
  TemplateNotFound,
} from "commonUI";
import { useAddRole, useRoles } from "api/usersModule/roles";
import { Link } from "react-router-dom";
import { useDeferredValue, useEffect, useState } from "react";
import { isEqual, isOwner, search } from "helpers";
import {
  libraryItemFiltering,
  libraryItemSorting,
} from "filterAndSort/templateLibrary";

export default function Roles() {
  const { data: roles, isLoading: loadingRoles } = useRoles();
  const { mutate: addRole, isLoading: addingRole } = useAddRole();

  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const defferedSearch = useDeferredValue(searchText);
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    if (roles?.length > 0) {
      setSearchedData(
        roles.filter(
          (_) =>
            search(_.role_title, defferedSearch) && !isEqual(_.nick, "owner")
        )
      );
    }
  }, [defferedSearch, roles]);

  const applySorting = (order, orderBy) => {
    setSearchedData((prevSearchResult) =>
      libraryItemSorting(order, orderBy, prevSearchResult, "role_title")
    );
  };

  const applyFilter = ({
    selectedFilter,
    selectedTags,
    selectedLibrarySource,
    ...data
  }) => {
    setFilterData({
      ...data,
      selectedTags,
      selectedLibrarySource,
      selectedFilter,
    });
    setSearchedData(
      libraryItemFiltering(
        selectedFilter,
        selectedTags,
        selectedLibrarySource,
        roles
      )
    );
  };

  return (
    <>
      <RightMainContainer>
        <Loader show={addingRole} loader="block" />
        <HeaderContainer>
          <PrimaryHeading>Roles</PrimaryHeading>
          <Link to="/users/roles/add">
            <MainButton>
              <AddIcon /> Add
            </MainButton>
          </Link>
        </HeaderContainer>
        <BodyContainer>
          <InputField
            searchIcon
            placeholder="Search"
            filter
            value={searchText}
            setValue={(value) => setSearchText(value)}
            showTags={false}
            onApplyFilter={applyFilter}
            applySorting={applySorting}
            sortOptions={[
              {
                label: "Alphabetical By Title",
                value: "alphabetical-by-title",
              },
              {
                label: "Date Added",
                value: "date-added",
              },
            ]}
          />
          <FiltersList filterData={filterData} updateFilter={applyFilter} />
          {loadingRoles ? (
            <LoadingListCards />
          ) : searchedData.length === 0 ? (
            <TemplateNotFound
              label="Role"
              linkLabel="Role"
              link="/users/roles/add"
            />
          ) : (
            searchedData.map((item, index) => (
              <ListCard
                key={index}
                data={item.role_title}
                icon={
                  <ListIconWrapper>
                    <RolesIcon fill="white" />
                  </ListIconWrapper>
                }
                secondaryData={isEqual(item.system_status, 1) ? "System" : ""}
                deleteIcon={true}
                duplicate={!isOwner(item)}
                editIcon={!isOwner(item)}
                link={`/users/roles/${item.id}`}
                strictLink
                onDuplicate={() =>
                  addRole({
                    data: {
                      ...item,
                      role_title: `Copy of ${item.role_title}`,
                      system_status: 0,
                    },
                  })
                }
              />
            ))
          )}
        </BodyContainer>
      </RightMainContainer>
    </>
  );
}
