import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./userAuth";
import tagsSlice from "./siteSettings/tagsReducer";
import statusReducer from "./siteSettings/statusReducer";
import priorityReducer from "./siteSettings/priorityReducer";
import tasksReducer from "./templateLibrary/tasks";
import notesSlice from "./templateLibrary/notes";
import pluginsSlice from "./templateLibrary/plugins";
import PluginListSlice from "./templateLibrary/pluginList";
import glossarySlice from "./templateLibrary/glossary";
import contentRequestsSliceItem from "./templateLibrary/contentRequestsItem";
import contentRequests from "./templateLibrary/contentRequests";
import phases from "./templateLibrary/phases";
import sitesSlice from "./sites";
import members from "./usersModule/members";
import roles from "./usersModule/roles";
import companies from "./usersModule/companies";
import groups from "./usersModule/groups";
import checklists from "./templateLibrary/checklists";
import smtp from "./siteSettings/smtp";
import maintenance from "./siteSettings/maintenance";
import environments from "./siteSettings/environments";
import emailTemplates from "./siteSettings/emailTemplates";
import tutorials from "./templateLibrary/tutorials";
import generalSettings from "./siteSettings/generalSetting";
import approvals from "./siteSettings/approvals";
import notifications from "./siteSettings/notifications";
import launchpadShortcuts from "./siteSettings/launchpadShortcuts";
import brandingAndColors from "./siteSettings/brandingAndColors";
import interfaceSetting from "./siteSettings/interface";

const rootReducer = combineReducers({
  user: userReducer,
  tags: tagsSlice,
  status: statusReducer,
  priorities: priorityReducer,
  siteTasks: tasksReducer,
  notes: notesSlice,
  plugins: pluginsSlice,
  pluginList: PluginListSlice,
  glossary: glossarySlice,
  contentRequestsItem: contentRequestsSliceItem,
  contentRequests: contentRequests,
  phases: phases,
  sites: sitesSlice,
  members,
  roles,
  companies,
  groups,
  checklists,
  smtp,
  maintenance,
  environments,
  emailTemplates,
  tutorials,
  generalSettings,
  approvals,
  notifications,
  launchpadShortcuts,
  brandingAndColors,
  interfaceSetting,
});

export default rootReducer;
