import { Route, Routes } from "react-router-dom";
import BrandingAndColor from "./BrandingAndColor";
import GeneralSettings from "../siteSettings/GeneralSettings";
import Notifications from "./Notifications/Index";
import SMTP from "./SMTP";
import { useSearch } from "helpers";
import Tags from "siteSettings/Tags";
import TagSetting from "siteSettings/Tags/TagSetting";

export default function SettingsRoutes() {
  const { web = null } = useSearch();
  return (
    <>
      <Routes>
        <Route exact path={`/`}>
          <Route
            exact
            index
            element={<GeneralSettings />}
          />
        </Route>
        <Route exact path={`/general-settings`}>
          <Route exact index element={<GeneralSettings />} />
        </Route>
        <Route exact path={`/notifications`}>
          <Route exact index element={<Notifications />} />
        </Route>
        <Route exact path={`/tags`}>
          <Route exact index element={<Tags isSettings />} />
          <Route exact path="/tags/:id" element={<TagSetting isSettings />} />
        </Route>
        {/* Here for future use */}
        {/* <Route exact path={`/email-templates`}>
          <Route exact index element={<EmailTemplate />} />
          <Route exact path="/email-templates/:id" element={<EmailSetting />} />
        </Route> */}
        <Route exact path={`/smtp`}>
          <Route exact index element={<SMTP />} />
        </Route>
        <Route exact path={"branding-and-colors"}>
          <Route exact index element={<BrandingAndColor />} />
        </Route>
      </Routes>
    </>
  );
}
