import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "helpers";

const initialState = {
  interfaceSetting: null,
  loaded: false,
  website_id: null,
};

const interfaceSettingSlice = createSlice({
  name: "interfaceSetting",
  initialState,
  reducers: {
    setInterfaceSettingAction: (state, action) => {
      const { interface_settings, website_id } = action.payload;
      state.interfaceSetting = interface_settings;
      state.website_id = website_id;
      state.loaded = true;
    },
    updateInterfaceSettingAction: (state, action) => {
      state.interfaceSetting = action.payload;
    },
  },
});

export const { setInterfaceSettingAction, updateInterfaceSettingAction } =
  interfaceSettingSlice.actions;
export default interfaceSettingSlice.reducer;
