export default function InterfaceIcon({ fill = "white", className = "" }) {
  return (
    <svg
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.26516 2.785C4.26516 3.205 4.60516 3.545 5.02516 3.545C5.44516 3.545 5.78516 3.205 5.78516 2.785V0.76C5.78516 0.34 5.44516 0 5.02516 0C4.60516 0 4.26516 0.34 4.26516 0.76V2.785ZM7.32016 4.545C7.46516 4.695 7.66016 4.77 7.85516 4.77H7.86016C8.05016 4.77 8.24516 4.695 8.39516 4.545L9.83016 3.11C10.1252 2.815 10.1252 2.33 9.83016 2.035C9.53516 1.74 9.05016 1.74 8.75516 2.035L7.32016 3.47C7.02516 3.765 7.02516 4.25 7.32016 4.545ZM2.19516 4.77C2.00016 4.77 1.80516 4.695 1.66016 4.545L0.225156 3.115C-0.0698441 2.82 -0.0698441 2.335 0.225156 2.04C0.520156 1.745 1.00516 1.745 1.30016 2.04L2.73516 3.475C3.03016 3.77 3.03016 4.255 2.73516 4.55C2.58516 4.7 2.39016 4.775 2.20016 4.775L2.19516 4.77ZM6.88516 8.595C8.23516 8.73 9.50516 9.33 10.4652 10.3L10.4702 10.305L10.8802 10.71V16.005H3.97516L0.715156 12.74C0.260156 12.295 0.0501559 11.685 0.120156 11.055C0.195156 10.425 0.545156 9.875 1.08016 9.54C1.58516 9.225 2.19516 9.145 2.77016 9.315V7.3C2.77016 6.565 3.16516 5.88 3.80016 5.515C4.43016 5.15 5.22016 5.15 5.85516 5.515C6.49016 5.88 6.88516 6.565 6.88516 7.3V8.595ZM2.59016 10.855C2.48016 10.745 2.32016 10.68 2.16516 10.68L2.17016 10.675C2.01516 10.675 1.85516 10.74 1.74516 10.85C1.63516 10.965 1.57016 11.115 1.57016 11.275C1.57016 11.435 1.63516 11.59 1.74516 11.7L4.58016 14.535H9.42016V11.3C8.58016 10.47 7.46016 10.01 6.27516 10.01H5.43516V7.29C5.43516 6.955 5.16016 6.685 4.83016 6.685C4.50016 6.685 4.22516 6.955 4.22516 7.29V11.69C4.22516 11.785 4.19516 11.865 4.13016 11.93C4.06516 11.99 3.98516 12.025 3.89516 12.025C3.80516 12.025 3.72516 11.99 3.66016 11.93L3.64516 11.91L2.59016 10.855Z"
        fill={fill}
      />
    </svg>
  );
}
