import { ModulePrefixes } from "enums";
import LeftMenuCard from "leftMenu/LeftMenuCard";
import { useLocation } from "react-router-dom";
import { getSettingsMenu } from "staticData";

export default function SettingseMenu() {
  const { search } = useLocation();
  return (
    <>
      <div className="card-div">
        {getSettingsMenu()
          .filter((_) => _)
          .map((item, index) => (
            <LeftMenuCard
              key={`${index}-${item.module}`}
              {...item}
              prefix={ModulePrefixes.SETTINGS}
              link={`${ModulePrefixes.SETTINGS}/${item.module}${search ?? ""}`}
            />
          ))}
      </div>
    </>
  );
}
