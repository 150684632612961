import { ExportIcon } from "allIcons";
import { InputField, MainButton, SecondaryHeading } from "commonUI";
import { t } from "i18next";
import { useNotification } from "notification";

export default function ApiKeyCard(props) {
  const notification = useNotification();
  const handleDownloadPlugin = () => {
    window.open(
      "https://api.wpsitelauncher.com/download/wp-sitelauncher-0.0.1.zip",
      "_blank"
    );
  };

  return (
    <>
      <div className="site-card">
        <div>
          <SecondaryHeading>{t("sites.apiKeyDetail.title")}</SecondaryHeading>
          <p className="body-text text-11 m-0">
            {t("sites.apiKeyDetail.body")}
          </p>
          <MainButton className="mt-2" onClick={handleDownloadPlugin}>
            <ExportIcon />
            {t("sites.apiKeyDetail.downloadPluginButton")}
          </MainButton>
        </div>
        <div className="w-full">
          <SecondaryHeading>
            {t("sites.apiKeyDetail.keyView.Title")}
          </SecondaryHeading>
          {props.apikey && (
            <div className="flex flex-row gap-10 justify-between items-center mt-1 w-full">
              <InputField
                value={props.apikey}
                className="flex-1"
                isLoading={props.isLoading}
              />
              {!props.isLoading && (
                <MainButton
                  className="-mt-2"
                  onClick={() => {
                    navigator.clipboard.writeText(props.apikey);
                    notification({
                      type: "SUCCESS",
                      message: t("sites.apiKeyDetail.keyView.copyMessage"),
                    });
                  }}
                >
                  {t("sites.apiKeyDetail.keyView.copyButton")}
                </MainButton>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
