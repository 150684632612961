import { RolesIcon, UserIcon } from "allIcons";

export const siteTempleteUsersMenu = [
    {
      module: "users",
      title: "Users",
      icon: <UserIcon />,
    },
    {
      module: "roles",
      title: "Roles",
      icon: <RolesIcon />,
    },
    // Here for future use
    // {
    //   module: "groups",
    //   title: "Groups",
    //   icon: <GroupIcon />,
  //   },
  // {
  //   module: "notifications",
  //   title: "Notifications",
  //   icon: <NotificationIcon />,
  // },
];
