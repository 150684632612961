export const pageTitles = {
  sites: {
    title: "sites.title",
    explainerText: "sites.explainerText",
  },
  "template-library": {
    title: "templateLibrary.title",
    explainerText: "templateLibrary.explainerText",
  },
  users: {
    title: "users.title",
    explainerText: "users.explainerText",
  },
  "site-settings": {
    title: "siteTemplate.title",
    explainerText: "siteTemplate.explainerText"
  },
  settings: {
    title: "settings.title",
    explainerText: "settings.explainerText",
  },
  "": {
    title: "sites.title",
    explainerText: "sites.explainerText",
  },
};
