import {
  SiteIcon,
  StatusIcon,
  PriorityIcon,
  PhaseIcon,
  TagsIcon,
  ErrorIcon,
  InterfaceIcon,
  LaunchPadIcon,
} from "allIcons";
import { compact } from "helpers";

export default function getGeneralMenu({ delete_site }) {
  const { search } = window.location;
  const generalMenu = [
    search &&{
      module: "general-settings",
      title: "General",
      icon: <SiteIcon />,
    },
    {
      module: "interface",
      title: "Interface",
      icon: <InterfaceIcon />,
    },
    {
      module: "launchpad-widgets",
      title: "Launchpad Widgets",
      icon: <LaunchPadIcon />,
    },
    {
      module: "phases",
      title: "Phases",
      icon: <PhaseIcon />,
    },
    {
      module: "status",
      title: "Status",
      icon: <StatusIcon />,
    },
    {
      module: "priority",
      title: "Priority",
      icon: <PriorityIcon />,
    },
    {
      module: "tags",
      title: "Tags",
      icon: <TagsIcon />,
    },
    search &&
      delete_site && {
        module: "advanced",
        title: "Advanced",
        icon: <ErrorIcon />,
      },
  ];

  return compact(generalMenu);
}
