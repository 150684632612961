import { ModulePrefixes } from "enums";
import LeftMenuCard from "leftMenu/LeftMenuCard";
import { useLocation } from "react-router-dom";
import { settingsMenu } from "staticData";

export default function SettingseMenu() {
  const { search } = useLocation();
  return (
    <>
      <div className="card-div">
        {settingsMenu.map((item, index) => (
          <LeftMenuCard
            key={`${index}-${item.module}`}
            {...item}
            prefix={ModulePrefixes.SETTINGS}
            link={`${ModulePrefixes.SETTINGS}/${item.module}${search ?? ""}`}
          />
        ))}
      </div>
    </>
  );
}
